import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "../global"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'


const Header = () => {


  const handleSubmit = async event => {
    event.preventDefault()
    var C = document.getElementById("text")

    var email = C.value
    console.log('Here' + email)

    try {
      let res = await axios.post(`https://us-central1-functions1-b66de.cloudfunctions.net/widgets/sub?email=${email}`)
      console.log(res.status)
      var T = document.getElementById("ThankyouText");
      T.style.display = "block";  // <-- Set it to block
      document.getElementById("SignUpButton").style.display = "none"
    } catch (error) {
      console.log(Error)
      var E = document.getElementById("ErrorText");
      E.style.display = "block";  // <-- Set it to block
    }




    C.value = ''
  }



  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              Here to help you take back control of your life
            </h1>
            <h2> APP LAUNCHING SOON</h2>
            <p>
              Sign up to join the Khai Home community &amp; receive exclusive updates
            </p>
            <HeaderForm onSubmit={handleSubmit}>


              <HeaderInput id="text" placeholder="Your email" />


              <HeaderButton id="SignUpButton">Sign-Up</HeaderButton>


            </HeaderForm>
            <div id="ThankyouText" style={{ display: 'none' }}>
              Thank you for signing up to join the Khai Home community 
              <span role="img" aria-label="heart"> 🤍</span>
            </div>
            <div id="ErrorText" style={{ display: 'none' }}>
              Something's gone wrong, please try again
              <span role="img" aria-label="heart"> 💔</span>
            </div>
          </HeaderTextGroup>

          <ImageWrapper>
            <StaticImage
              src="../../images/product/iphone-12-small-2.png"
              placeholder="tracedSVG"
              layout="constrained"
              alt="Khai Home phone"
            />
            <br />
          </ImageWrapper>
        </Flex>
        <Icon>
          <div className="icons"  >
            <a href="https://www.instagram.com/khai.home" aria-label="Save" style={{ color: "black" }}><FontAwesomeIcon icon={faInstagram} size="2x" style={{ marginRight: 10 }} /></a>
            <a href="https://www.facebook.com/profile.php?id=100064120667156" aria-label="Save" style={{ color: "black" }}><FontAwesomeIcon icon={faFacebook} size="2x" style={{ marginRight: 10 }} /></a>
            <a href="https://tiktok.com/@khai.home" aria-label="Save" style={{ color: "black" }}> <FontAwesomeIcon icon={faTiktok} size="2x" style={{ marginRight: 10 }} /></a>
          </div>
        </Icon>
      </Container>
    </HeaderWrapper>
  );
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #fff;
  padding: 160px 0 10px 0;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    justify-self: center;
    text-align: center;
    align-content: center;
    align-self: center;
    textAlign: center;
  }
`
const Icon = styled.div`
.icons {
  display: inline-grid;
  grid-template-columns: repeat(3,auto);
  gap: 10%;
  @media (max-width: ${props => props.theme.screen.sm}) {
    justify-self: center;
    text-align: center;
    align-content: center;
    align-self: center;
    textAlign: center;
  }
}
`



const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 40px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 30px;
  }
  br{
    margin-bottom: 20px;
  }

  #ThankyouText{
    @media (max-width: ${props => props.theme.screen.sm}) {
      display: block;
      width: 100%;
      justify-self: center;
      text-align: center;
      align-content: center;
      align-self: center;
      textAlign: center;
      margin: 0;

    }
  }


`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`


const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`


const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.primary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px white;
  }
  &:focus {
    box-shadow: inset ${props => props.theme.color.primary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background:${props => props.theme.color.primary} ;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
 
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    margin-bottom: 75px;
  }
`